<template>
  <v-container  v-if="localTourActive" class="instr_container">
    
    <!-- <div class="tour-overlay"></div> -->
      <div :style="highlightStyle" class="highlight-box"></div>
      <div class="tooltip_container" :style="tooltipStyle">
        <v-card class="simple_card">
        <v-card-title class="headline lighten-2" style="margin-bottom:-5px;justify-content:center;">       
          {{ currentStep.header }}
          <div @click="endTour" class="close_dialog"></div>
          <div style="" class="step_count">{{ currentStepIndex+1 }}/{{ stepsCount }}</div>
        </v-card-title>
       
        <v-card-text>
          
          <div class="tooltip" v-if="currentStep">
          <p v-html="currentStep.description"> </p>
          <div class="actions">
            <!-- <button @click="prevStep" :disabled="currentStepIndex === 0">Previous</button>
            <button @click="nextStep" :disabled="currentStepIndex === steps.length - 1">Next</button>
            <button @click="endTour">Skip</button> -->
            <div @click="nextStep" class="next_step"></div>
          </div>
        
        </div> 
          
        </v-card-text>
        
        
      </v-card>
        
        <div class="popup_bottom_robot"></div>
       </div>
  </v-container>
 
</template>

<script>
import $ from 'jquery';
export default {
  component: "InstructionTour",
  name: "InstructionTour",
  props: {
    tourActive: {
      type: Boolean,
      required: true,
    },
    steps: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentStepIndex: 0,
      localTourActive: this.tourActive,
    };
  },
  watch: {
    // Watch for changes in the prop and update the local state
    tourActive(newVal) {
      this.localTourActive = newVal;
      if(newVal) {
        $('#header, #footer').css('position','absolute');
        this.scrollToStep();
        
      }
      else{ $('#header, #footer').css('position','fixed');}
    },
  },
  computed: {
    stepsCount(){
      return this.steps.length

    },
    currentStep() {
      return this.steps[this.currentStepIndex];
    },
    highlightStyle() {
      if (!this.currentStep) return {};
      const target = document.querySelector(this.currentStep.selector);
      if (!target) return {};

      // eslint-disable-next-line no-unused-vars
      const headerHeight = document.querySelector('#header')?.offsetHeight || 0;
      const rect = target.getBoundingClientRect();
      

      // Scroll to the element with offset adjustments
     // const scrollPosition = window.scrollY + rect.top - headerHeight - 20; // Adjust with 20px padding
    const absoluteTop = rect.top + window.scrollY; // Get absolute position
    const scrollPosition = absoluteTop - headerHeight - 60; // Adjust with header height and padding
    //alert(absoluteTop);
      window.scrollTo({ top: scrollPosition, behavior: 'smooth' });

      return {
        position: 'absolute',
        //top: `${rect.top - headerHeight}px`, // Adjust for header
        top: `${absoluteTop - headerHeight - 5}px`, // Adjust for header
        left: `${rect.left}px`,
        width: `${rect.width}px`,
        height: `${rect.height + 10}px`,
        border: '2px solid white',
        boxShadow: '0 0 0 9999px rgba(0, 0, 0, 0.7)', // Dim surrounding
        pointerEvents: 'none',
      };
    },
    tooltipStyle() {
      if (!this.currentStep) return {};
      const target = document.querySelector(this.currentStep.selector);
      if (!target) return {};

      const rect = target.getBoundingClientRect();
      // eslint-disable-next-line no-unused-vars
      const headerHeight = document.querySelector('#header')?.offsetHeight || 0;
      const absoluteTop = rect.top + window.scrollY+rect.height; 
      return {
        position: 'absolute',
        //top: `${rect.bottom - headerHeight + 10}px`, // Tooltip below element
        top: `${absoluteTop - 60 + 10}px`, // Tooltip below element
        left: `${Math.max(10, rect.left)}px`, // Avoid clipping
       // background: '#fff',
        padding: '10px',
        borderRadius: '5px',
        boxShadow: '0px 4px 6px rgba(0,0,0,0.1)',
        zIndex: 10000,
        width: '95%',
      };
    },
  },
  methods: {
    scrollToStep() {
      if (!this.currentStep) return {};
      const target = document.querySelector(this.currentStep.selector);
      if (!target) return {};
      const headerHeight = document.querySelector('#header')?.offsetHeight || 0;
      const rect = target.getBoundingClientRect();
      
      const absoluteTop = rect.top + window.scrollY; 
      const scrollPosition = absoluteTop - headerHeight - 60; 
      //alert(absoluteTop);
      window.scrollTo({ top: scrollPosition, behavior: 'smooth' });

      
    },
    nextStep() {
      if (this.currentStepIndex < this.steps.length - 1) {
        this.currentStepIndex++;
      }else{
        this.endTour();
      }
    },
    prevStep() {
      if (this.currentStepIndex > 0) {
        this.currentStepIndex--;
      }
    },
    endTour() {
      this.currentStepIndex=0;
      this.localTourActive = false;
      this.$emit('updateTourActive', false);

      //this.$emit('update:tourActive', false);
      //this.currentStepIndex = 0;
    },
  },
};
</script>
  
  <style>
.instr_container{
  position: absolute;
  top:0;
  left:0;
  z-index: 99999 !important; 
    bottom: 0;
}
.tour-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Dim background */
  z-index: 10000;
  pointer-events: none;
}

.highlight-box {
  background: transparent;
  pointer-events: none;
}

.tooltip {
  color: #000;
  z-index: 10001;
  pointer-events: auto;
}

.actions {
  margin-top: 10px;
  display: flex;
  gap: 5px;
}

.step_count{
  
font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 24px;
display: flex;
align-items: center;
text-align: center;

color: #F07300;
position: absolute;
    left: 10px;
    top: 20px;
}

.next_step{
  position: absolute;
width: 22px;
height: 22px;

background: url(/img/next_step_i.png);
    background-size: 100% 100%;
   
    right: 17px;
    bottom: 14px;
}
  </style>
  
<style lang="css" scoped>

.v-dialog__content--active{
  background: rgba(0, 0, 0, 0.7) !important;
}

.custom_bg{
  background: 
  radial-gradient(50% 50% at 50% 50%, rgba(240, 115, 0, 0.35) 0%, rgba(240, 115, 0, 0) 100%), /* Radial gradient */
  white;
  background-blend-mode: normal;
}
</style>